// @nebular theming framework
@import '~@nebular/theme/styles/theming';
// @nebular out of the box themes
@import '~@nebular/theme/styles/themes';

:root {
  --color-white: #FFFFFF;
  --color-white-100: #F8F8F8;
  --color-white-150: #F2F2F2;
  --color-orange-200: #F79F25;
  --color-orange-300: #ED9924;
  --color-blue-100: #93B9D1;
  --color-blue-200: #3483FA;
  --color-blue-300: #0063A1;
  --color-black: #000000;
  --color-black-500: #1A1818;
  --color-grey-100: #C6C4C4;
  --color-grey-150: #B7B7B7;
  --color-grey-200: #848484;
  --color-grey-300: #59585D;
  --color-grey-400: #4E4E4E;
  --color-grey-500: #3D3C44;
  --color-grey-600: #282933;
  --color-brown-100: #59585D;
  --color-brown-300: #4B4242;
  --color-brown-400:  #322B2B;
  --color-brown-500: #292020;
  --color-green-100: #7ED321;
  --color-green-200: #53D258;
  --color-red-100: #D76363;
  --color-red-200: #C63636;
} 

$nb-themes: nb-register-theme((
  layout-padding-top: 2.25rem,
  menu-item-icon-margin: 0 0.5rem 0 0,
  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,
  select-min-width: 6rem,
  slide-out-background: #f7f9fc,
  slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
  slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
), default, default);

$nb-themes: nb-register-theme((
  layout-padding-top: 2.25rem,
  menu-item-icon-margin: 0 0.5rem 0 0,
  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,
  select-min-width: 6rem,
  slide-out-background: #252547,
  slide-out-shadow-color: 2px 0 3px #29157a,
  slide-out-shadow-color-rtl: -2px 0 3px #29157a,
), cosmic, cosmic);

$nb-themes: nb-register-theme((
  layout-padding-top: 2.25rem,
  menu-item-icon-margin: 0 0.5rem 0 0,
  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,
  select-min-width: 6rem,
  slide-out-background: linear-gradient(270deg, #edf1f7 0%, #e4e9f2 100%),
  slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
  slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
), corporate, corporate);

$nb-themes: nb-register-theme((
  background-basic-color-1: '#00c16d',
  background-basic-color-2: '#00c16d',
  background-basic-color-3: '#00c16d',
  background-basic-color-4: '#00c16d',
  layout-padding-top: 2.25rem,
  menu-item-icon-margin: 0 0.5rem 0 0,
  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,
  select-min-width: 6rem,
  slide-out-background: linear-gradient(270deg, #222b45 0%, #151a30 100%),
  slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
  slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
), dark, dark);
